import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  interval,
  fromEvent,
  merge,
} from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import AgoraRTC, {
  IAgoraRTCRemoteUser,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
} from 'agora-rtc-sdk-ng';
import FullScreen from 'react-request-fullscreen';
import LiveStreamPlayer from './LiveStreamPlayer';
import BottomControlBar from './BottomControlBar';
import useQuery from '../../hooks/useQuery';
import fetchWebinar from '../../network/fetchWebinar';
import fetchAudiences from '../../network/fetchAudiences';

const WebinarContainer = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  display: flex;
  overflow: hidden;
`;

const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID || '';

interface userTrackingType {
  userEmail?: string;
  userToken?: string;
  userFirstName?: string;
  userLastName?: string;
  userPhone?: string;
  userUuid?: string;
  userCompany?: string;
  userTitle?: string;
  userRole?: string;
  webinarName?: string;
}

declare global {
  interface Window {
    trackingItemToFirebase: (eventDomain: string, trackingInfo: any, eventType: string, eventSubType: string, eventName: string, eventInfo: string | number, boothId: string | number, boothName: string) => any;
  }
}

const Audience = () => {
  const query = useQuery();
  const { eventDomain, webinarId } = useParams<{ eventDomain: string, webinarId: string }>();
  const audienceJoinToken = query.get('audience_join_token');
  const vexpoAttendeeToken = query.get('vexpo_auth_token');

  const [trackingInfo, setTrackingInfo] = useState<any>(null);
  const queryTrackingInfoName = useMemo(() => `trackingParam_${eventDomain}`.toLowerCase().split('.').join('_'), [eventDomain]);
  const encodedTrackingParam = query.get('trackingParam')
    || window.localStorage.getItem(queryTrackingInfoName);

  const agoraUserId = query.get('agora_uid') as string;
  const agoraToken = query.get('agora_token') as string;
  const [tokenInfo, setTokenInfo] = useState<{ agoraToken: string; agoraUserId: string }>({ agoraToken: '', agoraUserId: '' });
  const [agoraChannelId, setAgoraChannelId] = useState<string>('');
  const [videoStreaming, setVideoStreaming] = useState<boolean>(false);
  const [screenSharing, setScreenSharing] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [audioTracks, setAudioTracks] = useState<IRemoteAudioTrack[]>([]);
  const audioStreaming = audioTracks.length > 0;

  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(80);

  const handleVolumeChange = useCallback((e, newValue) => {
    setVolumeLevel(newValue);
    audioTracks.forEach((track) => track.setVolume(newValue));
  }, [audioTracks]);

  const handleAudioMuteToggle = useCallback(() => {
    if (isAudioMuted) audioTracks.forEach((track) => track.setVolume(volumeLevel));
    else audioTracks.forEach((track) => track.setVolume(0));
    setIsAudioMuted(!isAudioMuted);
  }, [audioTracks, isAudioMuted, volumeLevel]);

  const [connected, setConnected] = useState(false);
  const playerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const shareScreenRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const webinarContainer = useRef() as React.MutableRefObject<any>;

  const client = useMemo(() => AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }), []);
  const isRecordingMode = useMemo<boolean>(() => (agoraToken !== null && agoraUserId !== null), [agoraToken, agoraUserId]);

  const trackUserToFireBase = useCallback(() => {
    window.localStorage.setItem(queryTrackingInfoName, encodedTrackingParam || '');
    if (encodedTrackingParam) {
      const trackingData = JSON.parse(decodeURIComponent(escape(window.atob(encodedTrackingParam))));
      setTrackingInfo({
        userEmail: trackingData.user_email,
        userToken: trackingData.user_token,
        userFirstName: trackingData.user_first_name,
        userLastName: trackingData.user_last_name,
        userPhone: trackingData.user_phone,
        userUuid: trackingData.user_uuid,
        userCompany: trackingData.user_company,
        userTitle: trackingData.user_title,
        userRole: trackingData.user_role,
        webinarName: trackingData.webinar_name,
      });
    }
  }, [queryTrackingInfoName, encodedTrackingParam]);

  const init = useCallback(async () => {
    if (audienceJoinToken && vexpoAttendeeToken) {
      const webinar = await fetchWebinar(eventDomain, webinarId);
      if (webinar) {
        setAgoraChannelId(webinar.agoraChannelId);
        if (isRecordingMode) {
          setTokenInfo({ agoraToken, agoraUserId });
        } else {
          const AudiencesTokenInfo = await fetchAudiences(eventDomain, webinarId, webinar, audienceJoinToken, vexpoAttendeeToken);
          if (AudiencesTokenInfo) setTokenInfo(AudiencesTokenInfo);
          trackUserToFireBase();
        }
      }
      return null;
    }
    return null;
  }, [agoraToken, agoraUserId, audienceJoinToken, eventDomain, webinarId, isRecordingMode, vexpoAttendeeToken, trackUserToFireBase]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    const trackSubscriber = () => fromEvent<[IAgoraRTCRemoteUser, 'audio' | 'video']>(client, 'user-published').pipe(
      mergeMap(([user, mediaType]: [IAgoraRTCRemoteUser, 'audio' | 'video']) => client.subscribe(user, mediaType).then((track) => {
        if (track.trackMediaType === 'video') {
          if ((track.getUserId() as string).startsWith('screen:')) {
            setScreenSharing(true);
            (track as IRemoteVideoTrack).play(shareScreenRef.current, { fit: 'contain' });
          } else {
            setVideoStreaming(true);
            (track as IRemoteVideoTrack).play(playerRef.current, { fit: 'contain' });
          }
        } else {
          track.play();
          (track as IRemoteAudioTrack).setVolume(volumeLevel);
          setAudioTracks([...audioTracks, track as IRemoteAudioTrack]);
        }
      })),
    );

    const trackUnpublished = () => fromEvent<[IAgoraRTCRemoteUser, 'audio' | 'video']>(client, 'user-unpublished').pipe(
      tap(([user, mediaType]: [IAgoraRTCRemoteUser, 'audio' | 'video']) => {
        if (mediaType === 'video') {
          if ((user.uid as string).startsWith('screen:')) {
            setScreenSharing(false);
          } else {
            setVideoStreaming(false);
          }
        } else {
          setAudioTracks(audioTracks.filter((audeioTrack) => audeioTrack.getUserId() !== user.uid));
        }
      }),
    );

    const disposable = merge(trackSubscriber(), trackUnpublished()).subscribe();
    return () => disposable.unsubscribe();
  }, [audioTracks, client, volumeLevel]);

  const isLiveStreaming = useMemo(() => true, []);

  useEffect(() => {
    let joined = false;

    const join = () => {
      joined = true;
      client.join(AGORA_APP_ID, agoraChannelId, tokenInfo.agoraToken, tokenInfo.agoraUserId);
      setConnected(true);
    };
    const leave = () => {
      joined = false;
      setAudioTracks([]);
      setVideoStreaming(false);
      setScreenSharing(false);
      setConnected(false);
      client.leave();
    };

    const disposable = interval(500).subscribe(() => {
      if (
        isLiveStreaming
        && !joined
        && tokenInfo.agoraUserId
        && tokenInfo.agoraToken
        && agoraChannelId
      ) join();
      else if (!isLiveStreaming && joined) leave();
    });

    return () => {
      if (joined) leave();

      disposable.unsubscribe();
    };
  }, [client, tokenInfo.agoraUserId, isLiveStreaming, agoraChannelId, tokenInfo.agoraToken]);

  const onFullScreenChange = useCallback(() => {
    if (isFullScreen) {
      setIsFullScreen(false);
    } else {
      setIsFullScreen(true);
    }
  }, [isFullScreen]);

  const toggleFullScreen = useCallback(() => {
    webinarContainer.current.fullScreen();
  }, []);

  return (
    <FullScreen
      ref={webinarContainer}
      onFullScreenChange={onFullScreenChange}
      onFullScreenError={() => { alert('FullScreen not supported'); }}
    >
      <WebinarContainer>
        <LiveStreamPlayer
          connected={connected}
          audioTracks={audioTracks}
          videoStreaming={videoStreaming}
          screenSharing={screenSharing}
          playerRef={playerRef}
          shareScreenRef={shareScreenRef}
          trackingParam={trackingInfo}
          eventDomain={eventDomain}
          webinarId={webinarId}
        />
        <BottomControlBar
          isRecordingMode={isRecordingMode}
          handleAudioMuteToggle={handleAudioMuteToggle}
          isAudioMuted={isAudioMuted}
          videoStreaming={videoStreaming}
          audioStreaming={audioStreaming}
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
        />
      </WebinarContainer>
    </FullScreen>
  );
};

export default Audience;
